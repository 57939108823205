import React from "react";

import "../styles/pages/Market.scss";

export default function Market() {
  return (
    <main className="market">
      <h1 className="market__title">Mercado</h1>
      <section className="market__content">
        <div className="market__content-menu"></div>
      </section>
    </main>
  )
}
